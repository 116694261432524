import { Component } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
//import { ActivatedRoute } from '@angular/router';
 
import { AuthService } from './services/auth.service';
import * as Cookies from 'js-cookie';

// import * as Cookies from '../.././node_modules/js-cookie/src/js.cookie';
// export namespace Cookies;
// import 'core-js/es7/reflect';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'True Cordis Login';
  showForm = false;
  form = new UntypedFormGroup ({

    ClientID: new UntypedFormControl(''),
    UserID: new UntypedFormControl(''),
    Password: new UntypedFormControl(''),
    CIDCheckbox: new UntypedFormControl(false),
    UIDCheckbox: new UntypedFormControl(false),
    // tStartDate: new FormControl(),
    

  });
  passwordBlur = false;
  userIDBlur = false;
  clientIDBlur = false;
  showMessage = false;
  message: string;
  messageColor: string;
  Cookies2;

  IncorrectLogin;
  failedLogin;
  successfulLogin;

  sessionGuid;
  employeeKeyNotFound;
  isMobile = false;

  constructor(
    public authService: AuthService,
    // public cookies: Cookies
  ) {

    this.isMobile = this.getIsMobile();
    window.onresize = () => {
      this.isMobile = this.getIsMobile();
    };
    console.log(document.documentElement.scrollHeight);
    console.log(document.documentElement.clientHeight);
    console.log(document.documentElement.offsetHeight);
    //  Cookies = this.cookies;
    // this.form.value.ClientID = 1111;
     if (Cookies.get('UserID') !== undefined) {
      this.form.controls.UserID.setValue(Cookies.get('UserID'));
      this.form.controls.UIDCheckbox.setValue(true);
      // this.form
    }
    // console.log(this.form);
     if (Cookies.get('ClientID') !== undefined) {
      this.form.controls.ClientID.setValue(Cookies.get('ClientID'));
      this.form.controls.CIDCheckbox.setValue(true);

      // this.form.value.ClientID = Cookies.get('ClientID');
    }
   }

   getIsMobile(): boolean {
    const w = document.documentElement.clientWidth;
    const breakpoint = 768;
    console.log(w);
    if (w < breakpoint) {
      return true;
    } else {
      return false;
    }
  }
  toggleForm() {
    this.showForm = !this.showForm;
    console.log('showForm value:', this.showForm);

  }
  setblur(control, valid) {
    console.log('control', control);
    console.log('valid', valid);
    if (valid) {
      const controlBlur = control + 'Blur';
      this[controlBlur] = true;
    }
  }

  onCheckboxChange(e, value) {
    console.log(e.target.checked);
    console.log(value);
    if (e.target.checked) {
      console.log('input checked');
      Cookies.set(value, this.form.value[value], { expires: 30 });
      console.log(Cookies.get(value));
    } else {
      console.log('input unchecked');
      Cookies.remove(value);
      console.log(Cookies.get(value));
      
    }
    // Cookies.set('UserID', this.form.value.UserID);

  };


  submitLogin(lTest:boolean) {

    const lSecure:boolean = true;
    console.log('submitLogin param lTest = ',lTest)
    if(lTest==null) {
      lTest = false;
    }
    // this.sessionGuid = '5884FCD7-AB7E-420C-A0DC-CFED660884B4';
    // this.sessionGuid = this.sessionGuid.replace(/-/g, '');
    // console.log('sessionGuid', this.sessionGuid);

    console.log(this.form);
    this.showMessage = true;
    if (this.form.status === 'INVALID') {
      this.message = 'One or more Fields are Invalid, please correct before trying again';
      this.messageColor = 'has-text-danger';
      console.log(this.message);
      setTimeout(() => {
        this.showMessage = false;
      }, 3000 );

    } else {

      if (this.form.controls.CIDCheckbox.value !== false) {
        Cookies.set('ClientID', this.form.controls.ClientID.value, { expires: 30 });
      }
      if (this.form.controls.UIDCheckbox.value !== false) {
        Cookies.set('UserID', this.form.controls.UserID.value, { expires: 30 });
      }
      this.message = 'login submitted';
      this.messageColor = 'has-text-secondary';
      console.log(this.message);
      setTimeout(() => {
        this.showMessage = false;
      } , 3000);

      console.log('cClientID', this.form.value.ClientID);
      console.log('cUserID', this.form.value.UserID);
      console.log('password', this.form.value.Password);

      this.authService.login(this.form.value.ClientID, this.form.value.UserID, this.form.value.Password, lTest, lSecure)
      .subscribe(
       (val: any) => {
           // console.log( val)
           if (  val['ajax'] !== null ) {
               console.log('val.ajax does exist');
           }
           console.log('POST call successful value returned in body', val);
           if (val['ajax'].code === '-10') {
               console.log(val['ajax'].message);
               // this.dataService.isLoggedIn = false;
               this.IncorrectLogin = true;
               this.failedLogin = true;
               this.successfulLogin = false;
             // this.flashMessagesService.show(e.ajax.message, { cssClass: 'alert-danger', timeout: 4000 });
             // this.router.navigate(['/login']);
           } else {
             // this.dataService.setSessionID(e['ajax'].login.kSessionID);
             // console.log(e['ajax'].login.kSessionID);
             // this.dataService.SessionID = val['ajax'].login.kSessionID;
             this.authService.setSessionID(val.ajax.login.kSessionID);
             // this.authService.setLoggedin(e['ajax'].login.kSessionID);
             this.authService.isLoggedInAuth = true;
             // this.dataService.isLoggedIn = true;
             this.IncorrectLogin = false;
             this.failedLogin = false;
             this.successfulLogin = true;
            //  this.sessionGuid = this.sessionGuid.replace(/-/g, '');
             this.sessionGuid = val.ajax.login.kSessionID.replace(/-/g, '');
            //  Cookies.set('ClientID', this.form.value.ClientID);
            //  Cookies.set('UserID', this.form.value.UserID);

             console.log('sessionGuid', this.sessionGuid);

             console.log('window.location.href', window.location.href);

             const lClientProtalSite = true;

             const cHref = 'http'+(lSecure ? 's' : '')+'://'
                + (lClientProtalSite ? 'client-portal.truecordis.co' : 'client.truecordis.com') 
                + '/client/index.html?gs='
                + this.sessionGuid + '&t='+(lTest ? '1' : '0');

             console.log('window.location.href', cHref);

             window.location.href = cHref;
             
             
             // get init info including full name
             // this.dataService.getInfo()
             // this.router.navigate(['Home']);
           }
       },
       response => {
           console.log('POST call in error', response);
       },
       () => {
           console.log('The POST observable is now completed.');
       });



    }
  }


   



}
