<!--The content below is only a placeholder and can be replaced.-->
<div class="columns is-centered bottomNone TCBlue " >
  <div class="column is-narrow bottomNone" >
      <div class="level TCBlue">
          <div class="level-left">
            <div class="level-item TCLogo "  >
        
              <!-- <img src="assets/TCLogo_1024x329.png" width="400"   alt="TrueCordis Logo"> -->
              <!-- <a href="http://www.truecordis.com"> -->
                <a href="https://truecordis.com/">
                <img src="assets/TCLogo_1024x329.png" width="350px"  alt="TrueCordis Logo">
              
              </a>
            </div>
          </div>
          
          <div class="level-right">
              <div class="level-item">
                  <a class=" navlink" 
                  href="https://truecordis.com/services"> Services</a>
              </div>
              <div class="level-item">
                  <a class=" navlink" 
                  href="https://truecordis.com/about/"> About</a>
              </div>
              <div class="level-item">
                  <a class=" navlink" 
                  href="https://truecordis.com/education-center/"> Education Center</a>
              </div>
              <div class="level-item">
                  <a class=" navlink" 
                  href="https://truecordis.com/contact-us/"> Contact</a>
              </div>
        
        <div class="level-item"></div>
            <div class="level-item">
        
              <a class="button signUpBTN " 
              
              href="http://signup.truecordis.com/"> Sign Up Now</a>
            </div>
          </div>
        </div>
        
  </div>
</div>


<div class="TCLibraryBG ">
    <!-- <img class="TCLibraryBG" src="assets/backgroundLibrary.jpg" alt=""> -->
  
    <span class="TCLibraryOverlay">
     
<div class="columns is-centered" style="top:150px;position:relative;  opacity:1;z-index: 100;">
   <!-- <div class="column"></div> -->
  <div class="column is-narrow is-5 box has-text-centered infoBox bottomNone" >
     
    <!-- 
    "@angular-devkit/build-angular": "~0.1102.19",
   -->
            
              <!-- <img width="82" height="82" src="https://truecordis.com/wp-content/uploads/2019/05/log-in-button.png" class="attachment-large size-large" alt="" srcset="https://truecordis.com/wp-content/uploads/2019/05/log-in-button.png 512w, https://truecordis.com/wp-content/uploads/2019/05/log-in-button-150x150.png 150w, https://truecordis.com/wp-content/uploads/2019/05/log-in-button-300x300.png 300w" sizes="(max-width: 512px) 100vw, 512px"> -->

              <img width="82" height="82" src="assets/log-in-button.png" class="attachment-large size-large" alt="" srcset="assets/log-in-button.png 512w, assets/log-in-button-150x150.png 150w, assets/log-in-button-300x300.png 300w" sizes="(max-width: 512px) 100vw, 512px">
              <h1 class="title">LOG IN
                  <hr class="" style="">
              </h1>
            
              <!-- <hr style="color:yellow"> -->
              
            <p (click)="submitLogin(true)" >
              QUESTIONS?
            </p>
            <p>
              Contact us anytime:
            </p>
            <p>
              1-800-760-2468 Toll Free
              <br>469-727-0457 Voice
              <br>1-877-915-1490 Fax
            </p>
            <p>
              316 N College Street 
              <br>Box H
              <br>Waxahachie, TX 75168
            </p>
            <!-- <p>
              3200 W. Pleasant Run Rd
              <br>Suite 420
              <br>Lancaster, TX 75146
            </p> -->
  <br>  
    </div>
  
  
  
    <div class="column is-narrow is-5 box bottomNone" >
  
      <form [formGroup]="form" class="bottomNone horizontalP10 ">

          <button class="button is-hidden is-link is-fullwidth"
          (click)="submitLogin(false)"  >
            LOG IN
          </button>
          <div class="notification is-secondary"
              *ngIf="!failedLogin && !successfulLogin">
              Please Login
          </div>
          <div class="notification  is-small is-danger" *ngIf="failedLogin">
              <button class="delete" (click)="failedLogin = false"></button>
              Login Unsuccessful
          </div>
          <div class="notification is-small is-success" *ngIf="successfulLogin">

              <button class="delete" (click)="successfulLogin = false"></button>
              Login Successful
          </div>
          <div class="field">
              <label class="label">Client ID</label>
              <div class="control">
                  <input class="input" pattern="[0-9]*$" type="text" 
                  size="4"  placeholder="e.g. 1111" 
                  maxlength="4"
                  formControlName="ClientID" 
                  [class.is-danger]="form.controls.ClientID.invalid && !form.controls.ClientID.pristine"
                  [class.is-warning]="form.controls.ClientID.value.length < 4 && !form.controls.ClientID.pristine"
                  [class.is-success]="form.controls.ClientID.value.length === 4 && !form.controls.ClientID.invalid && clientIDBlur"
                  (focusout)="setblur('clientID', !form.controls.ClientID.invalid)"
                  required
                  />
                <p *ngIf="form.controls.ClientID.invalid && !form.controls.ClientID.pristine" class="help is-danger"> Client ID must be 4 numbers</p>
              </div>
              <label class="checkbox">
                <input type="checkbox" formControlName="CIDCheckbox"  #CID (change)="onCheckboxChange($event, 'ClientID')" >
                Save Client ID
              </label>
            </div>


            <div class="field">
                <label class="label">User ID</label>
                <div class="control">
                  <!-- pattern="^[a-zA-Z0-9]+$"  -->
                    <input class="input" 
                    
                   
                    type="text" 
                      placeholder="e.g. Username" 
                    maxlength="14"
                    formControlName="UserID" (input)="employeeKeyNotFound = false" 
                    [class.is-danger]="form.controls.UserID.invalid && !form.controls.UserID.pristine"
                    
                    
                    [class.is-success]=" !form.controls.UserID.invalid && userIDBlur"
                      (focusout)="setblur('userID', !form.controls.UserID.invalid)"
                    required
                    />
                  <!-- <p *ngIf="form.controls.UserID.invalid && !form.controls.UserID.pristine" class="help is-danger"> User ID cant contain symbols</p> -->
                </div>
                <label class="checkbox">
                  <input type="checkbox" formControlName="UIDCheckbox" (change)="onCheckboxChange($event, 'UserID')" >
                  Save User ID
                </label>
              </div>
              <div class="field">
                  <label class="label">Password</label>
                  <div class="control">
                      <input class="input" 
                    
                       type="password" 
                        placeholder="e.g. password" 
                      maxlength="14"
                      formControlName="Password" (input)="employeeKeyNotFound = false" 
                      [class.is-danger]="form.controls.Password.invalid && !form.controls.Password.pristine"
                      
                      [class.is-success]=" !form.controls.Password.invalid && passwordBlur"
                      (focusout)="setblur('password', !form.controls.Password.invalid)"
                      required
                      />  
                  </div>
                </div>
                  
                  
        
                  <div class="field  ">
                    <div class="control ">
                        <button class="button loginBTN is-fullwidth"
                        (click)="submitLogin(false)"  >
                          LOG IN
                        </button>
                    </div>
                    
                    <!-- <div class="control">
                      <button class="button is-text" (click)="toggleForm()">Cancel</button>
                    </div> -->
                  </div>
                <br>
                  <p  *ngIf="showMessage" >
                  <!-- {{messageColor}} -->
                    <span [class]="messageColor + ' has-text-weight-bold'" >  {{message}} </span>
                  </p>
                
            </form>
            
      
  
    </div>
    <!-- <div class="column"></div> -->
  </div>



      
        
      
      
      
      </span>
    
      
     
    </div>

<footer *ngIf="!isMobile" class=" footer TCBlue footLevel">

  <div class="TCBlue level  " >
    <div class="level-item" >
      
      <div class="level-item">
        <a class=" navlink navlink-footer" 
        href="https://truecordis.com/services"> Services</a>
      </div>
      <div class="level-item">
        <a class=" navlink navlink-footer" 
        href="https://truecordis.com/about/"> About</a>
      </div>
      <div class="level-item">
        <a class=" navlink navlink-footer" 
        href="https://truecordis.com/education-center/"> Education Center</a>
      </div>
      <div class="level-item">
        <a class=" navlink navlink-footer" 
        style=""
        href="https://truecordis.com/contact-us/"> Contact</a>
      </div>
    </div>


    <div class="level-item">
      <div class="level-item">
        <div class="credits">
  
              <!-- Website By: Marketing Strategy Coaches & Rojo 032 | Developer: Matthew Breitling -->
              Website By: Marketing Strategy Coaches & Rojo 032
              <!-- Designed By: Marketing Strategy Coaches & Rojo 032 | Developed By: Matthew Breitling -->
        </div>
      </div>
    </div>
    
  </div>
</footer>


<footer *ngIf="isMobile" class="footer TCBlue" style="padding-bottom:10px">
    <div class="level TCBlue">
 <div class="level-left">
  <div class="level-item">
    <a class=" navlink navlink-footer" 
    href="https://truecordis.com/services"> Services </a>
   </div>
   <div class="level-item">
     <a class=" navlink navlink-footer" 
     href="https://truecordis.com/about/"> About</a>
   </div>
   <div class="level-item">
     <a class=" navlink navlink-footer" 
     href="https://truecordis.com/education-center/"> Education Center</a>
   </div>
   <div class="level-item">
     <a class=" navlink navlink-footer" 
     style=""
     href="https://truecordis.com/contact-us/"> Contact</a>
   </div>
 </div>

  <div class="level-right">
    <div class="level-item">
      <div  class="credits">

            <!-- Website By: Marketing Strategy Coaches & Rojo 032 | Developer: Matthew Breitling -->
            Website By: Marketing Strategy Coaches & Rojo 032 
            <!-- Designed By: Marketing Strategy Coaches & Rojo 032 | Developed By: Matthew Breitling -->
      </div>
    </div>
  </div>
     
     </div>
 </footer>


