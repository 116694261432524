

import { Injectable, isDevMode } from '@angular/core';
// import { DataService } from '../services/data.service';

//import { Http } from '@angular/http';
import { HttpClient, HttpHeaders } from '@angular/common/http';

//import 'rxjs/add/operator/map'; old
//import { map } from 'rxjs/operators'

//import {Headers} from '@angular/http';
//import { Observable } from 'rxjs/Rx';

import { Observable, Subject, pipe } from 'rxjs';
// operators all come from `rxjs/operators`
import { map, takeUntil, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  sessionid;
  isLoggedInAuth: boolean;
  cURL;

  gIsTestDatabase: boolean = true;

  gService_Url: string;
  //cURL = '//client.truecordis.com/gaprs.jsonservice.website/Default.aspx?key={f65f0867-6544-4361-85d5-bd4d9ccc395a}&conn=GAPRIMARYFINAL.TEST';
  
  
  cBaseURL = 'https://data.truecordis.co/api/process/tcclientportal?src=login'
  //window.alert(cURL);
  //gService_Url_test = "/api";
  
  //gService_Url = "reports/tr.gaprs.jsonservice/service.ashx?key={f65f0867-6544-4361-85d5-bd4d9ccc395a}&conn=GAPRIMARYFINAL.TEST";
  //gService_Url = "/reports/tr.gaprs.jsonservice/service.ashx?key={f65f0867-6544-4361-85d5-bd4d9ccc395a}&conn=GAPRIMARYFINAL.TEST";
  //gService_Url_production = "/reports/tr.gaprs.jsonservice/service.ashx?key={f65f0867-6544-4361-85d5-bd4d9ccc395a}&conn=GAPRIMARYFINAL.TEST";


  setSessionID(kSessionID) {
    //this.SessionID = kSessionID;
    localStorage.setItem('SessionID', JSON.stringify(kSessionID));
    //console.log(kSessionID);
   }
  getSessionID() {
    let localStorageItem = JSON.parse(localStorage.getItem('SessionID'));
    //console.log('getSessionID()',localStorageItem);
    return localStorageItem;
  }

  constructor(
    public http: HttpClient,
    // private dataService:DataService,
  ) { }
  //  Login User
  login(cClientID: string, cUserID: string, cPassword: string, lTest: boolean, lSecure: boolean) {
    this.getAjaxURL(lTest);
    console.log('cClientID', cClientID);
    console.log('cUserID', cUserID);
    console.log('password', cPassword);

    const ljInput = {
      'ajax':
        {
          'cParam': 'a14f9b'
          // , 'cSection': (llLogin ? 'LOGON' : 'LOGOFF')
          ,'lTest':lTest
          , 'cSection': 'LOGON'
          , 'logon':
            {
              'cSessionID': null
              , 'cClientID': cClientID
              , 'cLogin': cUserID
              , 'cPassword': cPassword
            },
           'origin':
            {
              'cBrowser': navigator.appName + ':' + navigator.userAgent
              , 'cIP': '12.12.12.1'
            }
          , 'data': null
        }
    };

    let data: any = ljInput;
    data = JSON.stringify(data);
    const myHeaders = new HttpHeaders()
      .set("Access-Control-Allow-Headers", "Content-Type")
      .set("Content-Type", "application/json; charset=utf-8")
      //.set("timeout", "100000")
      .set("Access-Control-Allow-Origin", "*")
      .set("Access-Control-Allow-Methods", "GET,POST");
    
    //.set("Access-Control-Allow-Origin", "https://data.truecordis.co")
    //.set("Access-Control-Allow-Headers", "Content-Type");
    //.set("Access-Control-Allow-Origin", "http://localhost");
    //"Access-Control-Allow-Methods", "GET, POST, PUT, DELETE"
    //Access-Control-Allow-Headers", "Content-Type"
    //http://localhost:4200
    //console.log(myHeaders.get('timeout'))

    console.log(data);
    console.log(myHeaders);
    //let cURL:string;
    //cURL = this.cURL // +'&mode=' + (this.gIsTestDatabase ? 'test' : 'production')
    console.log('cURL = ',this.cURL);

    return this.http.post(this.cURL, data, { headers: myHeaders }) //.pipe(map(response => response))
  }

  // Set the url depending on whether test mode or not.  Note: if in DevMode then will force to test.
  getAjaxURL(lTest:boolean) {
    //if (isDevMode() || lTest == true) {
    if (lTest == true) {
      this.cURL = this.cBaseURL+'&mode=test';
    } else {
      this.cURL = this.cBaseURL+'&mode=production';
    }
    console.log('isDevMode() = ', isDevMode())
  }

  //  Set User Status
  setLoggedin(SessionID:any) {
    this.sessionid = SessionID;
    if (SessionID !== null) {
      this.isLoggedInAuth = true;
    } else {
      this.isLoggedInAuth = false;
    }
  }

  // Check User status
  getAuth() {
    if (this.isLoggedInAuth) {
      return true;
    } else {
      return false;
    }
  }

  // Logout User
  logout() {
    this.isLoggedInAuth = false;

    const ljInput = {
      'ajax':
        {
          'cParam': 'b298ea'
          // , 'cSection': (llLogin ? 'LOGON' : 'LOGOFF')
          , 'cSection': 'LOGOFF'
          , 'logon':
            {
              'cSessionID': this.getSessionID()
              /*,'cClientID':  $('#cClientID').val()*/
              , 'cLogin': ''
            }
          , 'origin': {}
          , 'data': null
        }
    };

    let data: any = ljInput;
    // data.ajax.cSection = "HotOrNot";
    // data.ajax.cOperation = "List";
    data = JSON.stringify(data);
    const myHeaders = new HttpHeaders();

    myHeaders.append('Content-Type', 'application/json; charset=utf-8');

    return (
      this.http.post(this.cURL, data, { headers: myHeaders })
        .pipe(map(result => result))
    );

  }

}